<template>
  <div class="col-12">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">{{ translations.import_id }}</th>
          <th scope="col">{{ translations.created_at }}</th>
          <th scope="col">{{ translations.updated_at }}</th>
          <th scope="col">{{ translations.filename }}</th>
          <th scope="col">{{ translations.owner }}</th>
          <th scope="col">{{ translations.current_status }}</th>
          <th scope="col">{{ translations.upload_details }}</th>
        </tr>
      </thead>
      <tbody v-if="data.length">
        <tr v-for="(item, index) in data" :key="index">
          <td>{{ item.id }}</td>
          <td>{{ formatDatetime(item.created_at) }}</td>
          <td>{{ formatDatetime(item.updated_at, item.status) }}</td>
          <td>{{ item.original_filename }}</td>
          <td>{{ item.created_by }}</td>
          <td>
            <b-badge :variant="formatStatusLabel(item.status)">
              <h6 class="badge-status text-capitalize">{{ item.status }}</h6>
            </b-badge>
          </td>
          <td>
            <b-button
              v-if="shouldRenderExport && isCompletedStatus(item.status)"
              data-testid="export-button"
              v-b-tooltip.hover
              size="sm"
              :title="translations.export"
              variant="light"
              class="col-2 btn-table-action"
              @click="emitEvent('export', item.id)">
              <feather type="download"></feather>
            </b-button>
            <b-button
              data-testid="view-details-button"
              v-b-tooltip.hover
              size="sm"
              :title="translations.view_details"
              variant="light"
              class="col-2 btn-table-action"
              @click="emitEvent('details', item.id)">
              <feather type="eye"></feather>
            </b-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7" class="text-center">{{ translations.empty_import_list }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import translations from '@/translations';
import { formatDate } from '@/helpers/finance';

export default {
  name: 'ManualTransactionsList',
  props: {
    data: {
      type: Array,
    },
  },
  computed: {
    shouldRenderExport() {
      return this.$listeners.export;
    },
  },
  data() {
    return {
      translations: translations.finance.import_transactions.tab_manual.status_table,
    };
  },
  methods: {
    formatDatetime(date, status) {
      if (status?.toLowerCase() === 'pending') return '-';
      return formatDate(date, 'YYYY-MM-DD HH:mm:ss');
    },
    formatStatusLabel(label) {
      if (this.isCompletedStatus(label)) {
        return 'success';
      }
      return 'secondary';
    },
    isCompletedStatus(status) {
      return status.toLowerCase() === 'completed';
    },
    emitEvent(event, id) {
      this.$emit(event, id);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-table-action {
  min-width: 50px;
  margin-left: 8px;
}

.badge-status {
  margin-bottom: 0;
}
</style>
