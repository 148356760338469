<template>
  <b-col>
    <b-row>
      <b-form-file
        class="flex-1"
        :placeholder="translations.file_placeholder"
        required
        :drop-placeholder="translations.drop_placeholder"
        v-model="transactions_import_file"
        accept=".csv">
      </b-form-file>
      <b-button :disabled="uploading" @click="uploadTransactionsFile" class="ml-1">
        <b-spinner label="Loading..." variant="light" small v-if="uploading"></b-spinner>
        <span v-else>{{ translations.button.upload }}</span>
      </b-button>
      <b-button
        :disabled="false"
        @click="createFileFromBufferAndDownload(templateHeader, 'template_pepm.csv')"
        class="ml-1">
        {{ translations.button.download_template }}
      </b-button>
    </b-row>
    <b-row class="border mt-3">
      <b-col class="justify-content-start align-items-start py-3">
        <p><b>{{ translations.tab_pepm.instructions.line_1 }}</b></p>
        <p>{{ translations.tab_pepm.instructions.line_2 }}</p>
        <p>{{ translations.tab_pepm.instructions.line_3 }}</p>
        <p>{{ translations.tab_pepm.instructions.line_4 }}</p>
        <p>{{ translations.tab_pepm.instructions.line_5 }}</p>
        <p>{{ translations.tab_pepm.instructions.line_6 }}</p>
        <p>{{ translations.tab_pepm.instructions.line_7 }}</p>
        <p><b>{{ translations.tab_pepm.instructions.line_8 }}</b></p>
        <p>{{ translations.tab_pepm.instructions.line_9 }}</p>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-card
        class="border-0 client-section mb-2 mt-3 flex-fill text-center"
        :header="translations.transaction_status_header"
        body-class="px-0"
        header-class="border-0 font-weight-bold">
        <div class="row">
          <div class="col-12">
            <manual-transactions-list
            :data="manual_transactions_list"
            @details="onDetailsEvent"/>
          </div>
        </div>
      </b-card>
    </b-row>
  </b-col>
</template>

<script>
import translations from '@/translations';
import { parseResponseError } from '@/http/parsers/parse_response';
import utils from '@/scripts/tools/utils';
import service from '@/services/finance-service';
import ManualTransactionsList from '@/components/Financial/ManualTransactionsList.vue';

export default {
  name: 'ImportTransactionsPEPM',
  components: {
    ManualTransactionsList,
  },
  created() {
    this.templateHeader = [
      'Client ID',
      'Billing Configuration ID',
      'Quantity',
      'Unit value',
      'Transaction Date',
      'Observations',
      'Original PEPM Transaction ID\n',
    ].join(',');
  },
  data() {
    return {
      translations: translations.finance.import_transactions,
      transactions_import_file: null,
      uploading: false,
      loading: true,
      manual_transactions_list: [],
    };
  },
  async beforeMount() {
    try {
      this.manual_transactions_list = await service.getManualTransactionsPEPM();
    } catch (err) {
      this.$noty.error(`${this.translations.results.error_loading_status_list} ${parseResponseError(err)}`);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async uploadTransactionsFile() {
      if (!this.transactions_import_file) {
        this.$noty.error(this.translations.results.error_no_file);
        return;
      }

      if (this.transactions_import_file.name.split('.').pop() !== 'csv') {
        this.$noty.error(this.translations.results.error_not_csv);
        return;
      }

      // Check if file size > 1 MB
      if (this.transactions_import_file.size > 1 * 1024 * 1024) {
        this.$noty.error(this.translations.results.error_size_limit);
        return;
      }

      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.transactions_import_file);

      try {
        const { data } = await service.uploadTransactionsPEPMImportFile(formData);
        if (data) this.manual_transactions_list.unshift(data);

        this.$noty.success(this.translations.results.upload_success);
      } catch (error) {
        if (error?.response?.status === 400 && error?.response?.data?.type === 'Buffer') {
          this.createFileFromBufferAndDownload(
            error.response.data.data, // first data is the Buffer object and second is the buffer data
            `${Date.now().toString()}_error_${this.transactions_import_file.name}`,
          );
          this.$noty.error(this.translations.results.error_invalid_file);
          return;
        }

        this.$noty.error(`${this.translations.results.upload_error}: ${parseResponseError(error)}`);
      } finally {
        this.uploading = false;
      }
    },
    onDetailsEvent(uploadID) {
      this.$router.push(`/finance/import-transactions-pepm/details/${uploadID}`);
    },
    createFileFromBufferAndDownload(buffer, fileName) {
      const bufferIsString = typeof buffer === 'string';
      const arrayBuffer = new ArrayBuffer(buffer.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < buffer.length; ++i) {
        view[i] = bufferIsString ? buffer.charCodeAt(i) : buffer[i];
      }

      utils.downloadFile(arrayBuffer, fileName);
    },
  },
};
</script>
