<template>
  <div class="p-3">
    <header class="mb-4">
      <h3>{{ translations.page_title }}</h3>
    </header>
    <b-row>
      <b-col class="mt-3">
        <b-tabs content-class="mt-3">
          <b-tab lazy :title="translations.tab_manual.title">
            <import-transactions />
          </b-tab>

          <b-tab lazy :title="translations.tab_pepm.title">
            <import-transactions-pepm />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import translations from '@/translations';
import ImportTransactions from './Transactions/import.vue';
import ImportTransactionsPepm from './TransactionsPEPM/import.vue';

export default {
  name: 'ManualTransactions',
  components: {
    ImportTransactions,
    ImportTransactionsPepm,
  },
  data() {
    return {
      translations: translations.finance.import_transactions,
    };
  },
};
</script>
