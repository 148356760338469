<template>
  <b-col>
    <b-row>
      <b-form-file
        class="flex-1"
        :placeholder="translations.file_placeholder"
        required
        :drop-placeholder="translations.drop_placeholder"
        v-model="transactions_import_file"
        accept=".csv">
      </b-form-file>
      <b-button :disabled="uploading" @click="uploadTransactionsFile" class="ml-1">
        <b-spinner label="Loading..." variant="light" small v-if="uploading"></b-spinner>
        <span v-else>{{ translations.button.upload }}</span>
      </b-button>
      <b-button
        :disabled="false"
        @click="createFileFromBufferAndDownload(templateHeader, 'template.csv')"
        class="ml-1">
        {{ translations.button.download_template }}
      </b-button>
    </b-row>
    <b-row class="border mt-3">
      <b-col class="justify-content-start align-items-start py-3">
        <p><b>{{ translations.tab_manual.instructions.line_1 }}</b></p>
        <p>{{ translations.tab_manual.instructions.line_2 }}</p>
        <p>{{ translations.tab_manual.instructions.line_3 }}</p>
        <ul>
          <li>enrollment_fee</li>
          <li>session_fee</li>
          <li>3_session_fee</li>
          <li>9_session_fee</li>
          <li>
            {{ `full_fee — ${translations.tab_manual.instructions.type_reason_optional}` }}
            <ul>
              <li>high_risk_member</li>
            </ul>
          </li>
          <li>full_fee_adjustment</li>
          <li>pain_score_reduction_fee</li>
          <li>treatment_completion</li>
          <li>
            {{ `1st_milestone — ${translations.tab_manual.instructions.type_reason_required}` }}
            <ul>
              <li>dpt_enrollment</li>
              <li>bloom_enrollment</li>
              <li>move_enrollment</li>
              <li>dpt_1st_session_Y2</li>
              <li>bloom_1st_session_Y2</li>
              <li>move_1st_engagement_Y2</li>
            </ul>
          </li>
          <li>
            {{ `2nd_milestone — ${translations.tab_manual.instructions.type_reason_required}` }}
            <ul>
              <li>dpt_3sessions</li>
              <li>bloom_3sessions</li>
            </ul>
          </li>
          <li>
            {{ `3rd_milestone — ${translations.tab_manual.instructions.type_reason_required}` }}
            <ul>
              <li>dpt_9sessions</li>
              <li>bloom_9sessions</li>
            </ul>
          </li>
        </ul>
        <p>{{ translations.tab_manual.instructions.line_4 }}</p>
        <p>{{ translations.tab_manual.instructions.line_5 }}</p>
        <p>{{ translations.tab_manual.instructions.line_6 }}</p>
        <p>{{ translations.tab_manual.instructions.line_7 }}</p>
        <p><b>{{ translations.tab_manual.instructions.line_8 }}</b></p>
        <p>{{ translations.tab_manual.instructions.line_9 }}</p>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-card
        class="border-0 client-section mb-2 mt-3 flex-fill text-center"
        :header="translations.transaction_status_header"
        body-class="px-0"
        header-class="border-0 font-weight-bold">
        <div class="row">
          <div class="col-12">
            <manual-transactions-list
            :data="manual_transactions_list"
            @export="onExportEvent"
            @details="onDetailsEvent"/>
          </div>
        </div>
      </b-card>
    </b-row>
  </b-col>
</template>

<script>
import moment from 'moment';
import translations from '@/translations';
import { parseResponseError } from '@/http/parsers/parse_response';
import utils from '@/scripts/tools/utils';
import service from '@/services/finance-service';
import ManualTransactionsList from '@/components/Financial/ManualTransactionsList.vue';

export default {
  name: 'ImportTransactions',
  components: {
    ManualTransactionsList,
  },
  created() {
    this.templateHeader = [
      'SWORD Patient ID',
      'Transaction Type',
      'Transaction Type Reason',
      'Value',
      'Description',
      'Event Date',
      'Billable Date',
      'Original Transaction ID\n',
    ].join(',');
  },
  data() {
    return {
      translations: translations.finance.import_transactions,
      transactions_import_file: null,
      uploading: false,
      loading: true,
      manual_transactions_list: [],
    };
  },
  async beforeMount() {
    try {
      const { data } = await service.getManualTransactionsList();
      this.manual_transactions_list = data;
    } catch (err) {
      this.$noty.error(`${this.translations.results.error_loading_status_list} ${parseResponseError(err)}`);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async uploadTransactionsFile() {
      if (!this.transactions_import_file) {
        this.$noty.error(this.translations.results.error_no_file);
        return;
      }

      if (this.transactions_import_file.name.split('.').pop() !== 'csv') {
        this.$noty.error(this.translations.results.error_not_csv);
        return;
      }

      // Check if file size > 1 MB
      if (this.transactions_import_file.size > 1 * 1024 * 1024) {
        this.$noty.error(this.translations.results.error_size_limit);
        return;
      }

      this.uploading = true;
      const formData = new FormData();
      formData.append('file', this.transactions_import_file);

      try {
        const { data } = await service.uploadTransactionsImportFile(formData);
        if (data) this.manual_transactions_list.unshift(data);

        this.$noty.success(this.translations.results.upload_success);
      } catch (error) {
        if (error?.response?.status === 400 && error?.response?.data?.type === 'Buffer') {
          this.createFileFromBufferAndDownload(
            error.response.data.data, // first data is the Buffer object and second is the buffer data
            `${Date.now().toString()}_error_${this.transactions_import_file.name}`,
          );
          this.$noty.error(this.translations.results.error_invalid_file);
          return;
        }

        this.$noty.error(`${this.translations.results.upload_error}: ${parseResponseError(error)}`);
      } finally {
        this.uploading = false;
      }
    },
    async onExportEvent(uploadID) {
      if (!uploadID) return;

      try {
        const buf = await service.exportManualTransactionsUpload(uploadID);
        const fileName = `export_${uploadID}_${moment().utc().format('YYYYMMDD_HHmmss')}.csv`;
        utils.downloadFile(buf, fileName);
      } catch (error) {
        this.$noty.error(`${this.translations.error_exporting}: ${parseResponseError(error)}`);
      }
    },
    onDetailsEvent(uploadID) {
      this.$router.push(`/finance/import-transactions/details/${uploadID}`);
    },
    createFileFromBufferAndDownload(buffer, fileName) {
      const bufferIsString = typeof buffer === 'string';
      const arrayBuffer = new ArrayBuffer(buffer.length);
      const view = new Uint8Array(arrayBuffer);
      for (let i = 0; i < buffer.length; ++i) {
        view[i] = bufferIsString ? buffer.charCodeAt(i) : buffer[i];
      }

      utils.downloadFile(arrayBuffer, fileName);
    },
  },
};
</script>
